import React from 'react';
import Seo from '../components/Seo/Seo';
import UniversalLink from '../components/_baseComponents/Link/UniversalLink';
import {TailwindTransitions} from '../utils/library/transitions/transitions';
import Rive from '@rive-app/react-canvas';
import NotFoundAnimation from '../assets/lottie/404.riv';

const NotFoundPage = () => {
  return (
    <div
      className={`flex flex-col items-center py-[70px] text-center lg:pt-20`}
    >
      <Rive
        src={NotFoundAnimation}
        width={800}
        height={800}
        role="img"
        aria-label="Not Found Animation"
        className="h-[300px] w-[300px] md:h-[450px] md:w-[450px] lg:h-[600px] lg:w-[600px]"
      />

      <h1 className="mt-10 text-2xl font-bold text-primary md:text-3xl lg:text-4xl">
        Trang này không tồn tại
      </h1>
      <UniversalLink
        to="/"
        className={`mt-10 rounded bg-primary px-4 py-2 text-white hover:bg-primary/80 ${TailwindTransitions.default[300]}`}
      >
        Về trang chủ
      </UniversalLink>
    </div>
  );
};

export default NotFoundPage;

export const Head = () => {
  return (
    <Seo title={'Không tìm thấy trang'} description={'Trang không tồn tại'} />
  );
};
